.col-trainer-alias {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col-trainer-alias div {
  max-width: 25%;
  padding-right: 5px;
}

.col-trainer-alias div img {
  width: 100%;
  height: auto;
}

.col-trainer-alias h4 {
  width: 75%;
  margin:0;
}

@media only screen and (max-width: 1200px) {
  .col-trainer-alias div {
    max-width: 35%;
    padding-right: 5px;
  }
}


@media only screen and (max-width: 800px) {
  
  .col-trainer-alias {
    flex-direction: column;
    align-items: baseline;
  }
  .col-trainer-alias div {
    max-width: 35%;
    padding-right: 5px;
  }
  .col-trainer-alias h4 {
    width: 100%;
    margin:0;
  }
}